/*
Author:      Zachary Thomas
Created:     8/5/2021
Modified:    8/5/2021

Copyright 2021 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

// Convert camelCase text to Tile Case text.
export function camelCaseToTitleCase(text) {
  const intermediateText = text.replace(/([A-Z])/g, " $1");
  return intermediateText.charAt(0).toUpperCase() + intermediateText.slice(1);
}